(function() {
    'use strict';

    angular.module('aerosApp')
        .component('aflModalCarouselIntegerInput', {
            controller: aflModalCarouselIntegerInputController,
            templateUrl: '/static/javascript/directives/afl-modal-carousel/afl-integer-input/afl-modal-carousel-integer-input.html',
            bindings: {
                group: '=',
                editable: '=',
                fieldDescription: '=',
                model: '=',
                mode: '=',
                fullDotName: '=',
                required: '=',
                visible: '=',
                fieldChangeEventHandler: '&',
                parent: '='
            }
        });

    function aflModalCarouselIntegerInputController() {
        var $ctrl = this;

        $ctrl.isVisible = function isVisible() {
            return typeof $ctrl.visible !== "undefined" ?  $ctrl.visible : true;
        };
    }
})();
